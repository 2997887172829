<template>
  <div>
    <div class="carousel-wrapper">
      <IconButton
        class="carousel__button carousel__button--back button--circle button--white"
        @click="goToSlide('prev')"
      >
        <IconArrowTop class="carousel__button-icon carousel__button-icon"></IconArrowTop>
      </IconButton>
      <VueSlickCarousel
        v-if="images.length"
        v-bind="carouselSettings"
        ref="carousel"
        @afterChange="changeSlide"
      >
        <div v-for="(slide, index) in images" :key="index" class="slide">
          <img class="slide__image" :src="slide.url" :alt="slide.caption" />
        </div>
      </VueSlickCarousel>
      <IconButton
        class="carousel__button carousel__button--next button--circle button--white"
        @click="goToSlide('next')"
      >
        <IconArrowTop class="carousel__button-icon carousel__button-icon"></IconArrowTop>
      </IconButton>
    </div>
    <p class="carousel-description text-description">{{ slideActive.caption }}</p>
  </div>
</template>
<script>
import IconButton from "@/components/buttons/IconButton.vue";
import IconArrowTop from "@/components/icons/IconArrowTop.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
  props: {
    images: { type: Array },
  },
  data() {
    return {
      carouselSettings: {
        centerMode: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: false,
        dots: false,
        infinite: true,
        centerPadding: "20px",
        variableWidth: true,
        responsive: [
          {
            breakpoint: 540,
            settings: {
              centerMode: false,
            },
          },
        ],
      },
      slideActive: {},
    };
  },
  created() {
    this.slideActive = this.images[0];
  },
  components: {
    IconButton,
    IconArrowTop,
    VueSlickCarousel,
  },
  methods: {
    goToSlide(moveDirection) {
      let carousel = this.$refs.carousel;
      switch (moveDirection) {
        case "prev": {
          carousel.prev();
          break;
        }
        case "next": {
          carousel.next();
          break;
        }
      }
    },
    changeSlide(slideIndex) {
      this.slideActive = this.images[slideIndex];
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel {
  &-wrapper {
    position: relative;
    width: 100%;
    .slide {
      padding: 0px 2.5px;
      @media screen and (max-width: $xs) {
        padding: 0;
      }
    }
    .slide {
      margin: 0 5px;
      &__image {
        margin-top: 15px;
        max-width: 80rem;
        max-height: 80rem;
        width: 80rem;
        height: 80rem;
        @media screen and (max-width: $xs) {
          width: 38rem;
          height: 38rem;
          max-width: 97vw;
        }
      }
    }
  }
  &-description {
    width: 100%;
    font-weight: 100;
    text-align: center;
    margin-top: 15px;
    transition: all 0.3s ease-in-out;
    @media screen and (max-width: $xs) {
      padding: 0 25px;
    }
  }

  &__button {
    position: absolute;
    width: 7rem;
    height: 7rem;
    top: calc(50% - 3.5rem);
    z-index: 1;
    @media screen and (max-width: $xs) {
      width: 4.2rem;
      height: 4.2rem;
      top: calc(50% - 2.1rem);
    }
    &--next {
      right: 10.5rem;
      transform: rotate(90deg) translateY(-50%);
      @media screen and (max-width: $xs) {
        right: 4rem;
      }
    }
    &--back {
      left: 10.5rem;
      transform: rotate(-90deg) translateY(-50%);
      @media screen and (max-width: $xs) {
        left: 4rem;
      }
    }
    &-icon {
      width: 18px !important;
      height: 12px !important;
      @media screen and (max-width: $xs) {
        width: 10px !important;
        height: 6px !important;
      }
    }
  }
}
</style>

